<template>
	<div id="cpm_seedEdit" class="el-content">
		<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
			<a-row>
			    <a-col :span="12">
					<a-form-item label="种子ID" v-if="!form.id">
						<a-input v-model:value="copy_id">
							<template #addonAfter>
								<div  @click="getSeedDetail(copy_id)">获取</div>
							</template>
						</a-input>
					</a-form-item>
					<a-form-item label="种子名称" required>
						<a-input v-model:value="form.send_name"></a-input>
					</a-form-item>
					<a-form-item label="种子分类" v-if="seedType.length>0">
						<a-select v-model:value="form.cate_id">
							<a-select-option :value="item.id" v-for="(item,index) in seedType" :key="index">{{item.name}}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="轮播图" >
						<kd-img-select :mutil="true" :src="form.send_slide" @success="(url)=>{ form.send_slide = url }"></kd-img-select>
					</a-form-item>
					<a-form-item label="幼苗期图片" >
						<kd-img-select :src="form.seedling_img" @success="(url)=>{ form.seedling_img = url }"></kd-img-select>
					</a-form-item>
					<a-form-item label="生长期图片" >
						<kd-img-select :src="form.grow_img" @success="(url)=>{ form.grow_img = url }"></kd-img-select>
					</a-form-item>
					<a-form-item label="成熟图片" >
						<kd-img-select :src="form.mature_img" @success="(url)=>{ form.mature_img = url }"></kd-img-select>
					</a-form-item>
					<a-form-item label="适宜播种季节" >
						<a-select v-model:value="form.planting_month" mode="multiple" placeholder="请选择">
							<a-select-option v-for="item in 12" :key="item" :value="item">{{item}}月</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="排序" >
						<a-input v-model:value="form.rank"></a-input>
					</a-form-item>
					<a-form-item :wrapper-col="{offset:4}">
						<a-space>
							<a-button @click="submitSave" type="primary" >保存</a-button>
							<a-button @click="$router.go(-1)">返回</a-button>
						</a-space>
					</a-form-item>
				</a-col>
			    <a-col :span="12">
					<a-form-item label="价格" required>
					  	<a-input v-model:value="form.price" type="number" min="0" addon-after="元/㎡" ></a-input>
					</a-form-item>
					<a-form-item label="最少种植面积" required>
						<a-input v-model:value="form.low_count" addon-after="㎡" type="number" min="0"></a-input>
					</a-form-item>
					<a-form-item label="种子产量">
						<a-input v-model:value="form.output" type="number" min="0" addon-after="kg/㎡" ></a-input>
					</a-form-item>
					<a-form-item label="生长周期" >
						<a-input v-model:value="form.cycle" type="number" min="1" addon-after="天"></a-input>
					</a-form-item>
					<a-form-item label="采摘周期" >
						<a-input v-model:value="form.pickingcycle" type="number" min="0" addon-after="天"></a-input>
					</a-form-item>
					<a-form-item label="是否热门" >
						<a-radio-group v-model:value="form.is_hot">
						  <a-radio :value="1">是</a-radio>
						  <a-radio :value="0">否</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="种子介绍">
						<tinymce-editor v-model="form.summary"></tinymce-editor>
					</a-form-item>
				</a-col>
			</a-row>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import landModel from '@/api/land.js'
export default{
	name:'page-seed-edit',
	props:{
		isShop:{
			type:Number
		}
	},
	setup(){
		const _d = reactive({
			copy_id:'',
			form:{
				rank:99,
				cover:'',
				price:'',
				cycle:'',
				output:'',
				cate_id:"",
				effect:'#',
				send_name:'',
				low_count:'',
				send_slide:[],
				mature_time:'',
				planting_month:[],//适宜播种季节
				send_time:'#',
				is_put_away:1,
				seed_desc:'',
				pickingcycle:'',	//采摘周期
				is_hot:0,
				summary:'',
				seedling_img:"",
				grow_img:"",
				mature_img:""
			},
			seedType:[],		//种子分类
		})
		const opt = useRoute().query
		if(opt.id) {
			getSeedDetail(opt.id)
		}
		function getSeedDetail(id){
			let keys = Object.keys(_d.form)
			landModel.getSeedDetail(id,keys,res=>{
				_d.form = res
				if( opt.id ) _d.form.id = opt.id
			})
		}

		landModel.getSeedType(res=>_d.seedType = res)
		const submitSave = ()=> landModel.addOrEditSeed(_d.form)
		return{
			...toRefs(_d),
			submitSave,
			getSeedDetail
		}
	},
}
</script>

<style>
</style>
